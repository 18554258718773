// import store from '../stores/app-store' // need to be at top to solve webpack issue.
import axios from 'axios'
import Config from '../config'
import { AxiosInstance } from '../helpers/AxiosInstance'

let settings: any = []

const getAllSettings = () => {
    if (settings.length) {
        return new Promise((resolve, reject) => {
            resolve(settings)
        })
    }

    return AxiosInstance.get(`${Config.API_URL}/api/Settings`).then((res) => {
        settings = res.data
        return res.data
    })
}

const updateSetting = (data: any) => {
    data = JSON.parse(JSON.stringify(data))
    data.value = data.value.toString()
    return AxiosInstance.put(`${Config.API_URL}/api/Settings/Update`, data).then(
        (res) => {
            settings = res.data
            return res.data
        }
    )
}

const updateActivityFeedSetting = (userId: string, data) => {
    return axios.put(`${Config.API_SECOND_URL}/securefileshare/api/v1/update/notification/status/${userId}`, data).then(
        (res) => {
            // settings = res.data
            return res.data
        }
    )
}

const getActivityFeedNotificationStatus = (userId: string) => {
    return axios.get(`${Config.API_SECOND_URL}/securefileshare/api/v1/get/notification/status/${userId}`).then(
        (res) => {
            return res.data
        }
    )
}


const getSettingByCode = (code: any) => {
    return getAllSettings().then((res: any) => {
        var data = res.filter((x: any) => x.code === code)
        return data.length ? data[0] : null
    })
}

const hasTwoFactAuthEnabled = () => {
    return getSettingByCode('TFA').then((res) => {
        return !!res && !!res.value && res.value.toString().toLowerCase() === 'true'
    })
}

const getSettingByPhoneNumber = (number: any) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Settings/phone/${number}`)
}
const UpdatePhoneSettings = (number: any, body: any) => {
    return AxiosInstance.patch(
        `${Config.API_URL}/api/Settings/phone/${number}`,
        body
    )
}
const getOrganizationSettings = (orgId: any) => {
    return AxiosInstance.get(
        `${Config.API_URL}/api/Settings/organization/${orgId}`
    )
}

// function getManualsubscription(orgId,code){
//     return AxiosInstance.get(
//         `${Config.API_URL}/api/Settings/organization/${orgId}/${code}`
//     )
// }

const UpdateOrganizationSettings = (orgId: any, body: any) => {
    return AxiosInstance.patch(
        `${Config.API_URL}/api/Settings/organization/${orgId}`,
        body
    )
}

const getUserPreferredTimeZone = (): string => {
    try {
        return localStorage.getItem("Timezone")
    } catch {
        return ''
    }
}

export const SettingsService = {
    Notification_Code: 'WNA',
    Pinned_Contacts_Code: 'MPC',
    Message_Contacts_Code: 'MMC',
    Group_Contacts_Code: 'MGC',
    Two_FactAuth_Code: 'TFA',
    Voice_Otp_Veification: 'VOICOTP',
    Wrong_Password_Attmps_Code: 'MWPA',
    Time_Zone_Code: 'TMZ',
    Auto_Time_Zone_Code: 'SATZ',
    SIGNATURE: 'SIGN',
    SIGNATURE_TO_ALL_MESSAGES: 'STAM',
    OUT_BOUND: 'PNFOM',
    MANUAL_INVOICE: 'MS',


    getAllSettings,
    getSettingByCode,
    updateSetting,
    hasTwoFactAuthEnabled,
    getSettingByPhoneNumber,
    UpdatePhoneSettings,
    getOrganizationSettings,
    UpdateOrganizationSettings,
    getUserPreferredTimeZone,
    updateActivityFeedSetting,
    getActivityFeedNotificationStatus
    // getManualsubscription
}
